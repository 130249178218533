/* body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  
  .container {
    display: flex;
    align-items: center;
    height: 150px;
  }
  
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0px 10px;
    background-color: #02AAF3;
    animation: firstResize 1s infinite;
    margin-right: 10px
  }
  
  @keyframes firstResize {
      0% { width: 6px; height: 6px; }
      25% { width: 12px; height: 12px}
      50% { width: 20px; height: 20px; }
      75% { width: 12px; height: 12px; }
      100% { width: 6px; height: 6px; }
  }
  
  #second {
    animation-delay: 100ms;
  }
  
  #third {
    animation-delay: 200ms;
  }

  #fourth {
    animation-delay: 300ms;
  }

  #fifth {
    animation-delay: 400ms;
  }